const encryptEmail = (email: string): string => {
  // Simple hash function to create a fixed-length representation of the email
  const hash = (str: string): number => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash |= 0; // Convert to 32bit integer
    }
    return Math.abs(hash); // Ensure the hash is non-negative
  };

  // Convert the hash to a base64 string to include special characters
  const base64Encode = (num: number): string => {
    const chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
    let encoded = '';
    let n = num;
    do {
      encoded = chars[n % 64] + encoded;
      n = Math.floor(n / 64);
    } while (n > 0);
    return encoded;
  };

  // Generate the hash and encode it
  const emailHash = hash(email);
  const encodedEmail = base64Encode(emailHash);

  // Replace '+' with '-', '/' with '_', and remove '=' to make it URL-safe
  const urlSafeHash = encodedEmail
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');

  return urlSafeHash;
};

export default encryptEmail;
