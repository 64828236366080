import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import get_graph_data_api_call from '../../../../lib/api/stats/get_graph_data_api_call';
import ChartWithConfig from '../../ChartWithConfig';
import TimeOfDayCRLineChart from './TimeOfDayCRLineChart';
import DayOfWeekCRLineChart from './DayOfWeekCRLineChart';
import isNotEmptyString from '../../../../lib/helper-functions/isNotEmptyString';

type Props = {
  timeFilter: string;
  eventFilter: string;
};

const MainTimeAndDayCRChart = ({
  timeFilter,
  eventFilter = 'purchase',
}: Props) => {
  const { t } = useTranslation();
  const stats = useSelector((state: RootState) => state.user?.stats);
  const [params, setParams] = useState<string>(
    `?graph_type=conversion_rate&group_by=${timeFilter}&time_range=all&event_type=${eventFilter}`
  );
  const [response, setResponse] = useState<any>('initial');

  const [title, setTitle] = useState<string>(t('ConversionRateByTimeOfDay'));

  /* ---------API CALL----------- */
  const getGraphData = async () => {
    try {
      // Call to get conversion rate stats by location
      const res = await get_graph_data_api_call(
        stats.ai_tracker_api_key,
        stats.ai_tracker_access_token,
        params
      );

      if (res) {
        if (timeFilter === 'time_of_day') {
          setResponse({
            type: 'TimeOfDay',
            data: res,
          });
        } else {
          setResponse({
            type: 'DayOfWeek',
            data: res,
          });
        }
      }
    } catch (error) {
      console.error('Error fetching conversion rate data:', error);
    }
  };

  useEffect(() => {
    let eventURLParam = '';
    if (isNotEmptyString(eventFilter)) {
      eventURLParam = `&event_type=${eventFilter}`;
    }

    setParams(
      `?graph_type=conversion_rate&group_by=${timeFilter}&time_range=all${eventURLParam}`
    );

    if (timeFilter === 'time_of_day') {
      setTitle(t('ConversionRateByTimeOfDay'));
    } else if (timeFilter === 'day_of_week') {
      setTitle(t('ConversionRateByDayOfWeek'));
    }
  }, [timeFilter]);

  useEffect(() => {
    if (
      stats.ai_tracker_api_key !== '' &&
      stats.ai_tracker_access_token !== ''
    ) {
      setResponse('initial');
      getGraphData();
    }
  }, [params, stats.ai_tracker_access_token, stats.ai_tracker_api_key]);

  function renderChart() {
    if (timeFilter === 'time_of_day') {
      return <TimeOfDayCRLineChart apiResponse={response} />;
    } else {
      return <DayOfWeekCRLineChart apiResponse={response} />;
    }
  }

  return <ChartWithConfig title={title}>{renderChart()}</ChartWithConfig>;
};

export default MainTimeAndDayCRChart;
