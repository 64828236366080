import {
  setAiOnboardingInprogress,
  setIsAiOnboardingCompleted,
  setAiOnboardingNextStep,
  setAiOnboardingStepCompleted,
  setIsAiOnboardingIntegrationConnected,
  setIsTrackerClassesConfigured,
} from '../../store/reducers/userSlice';
import store from '../../store/store';
import { fetchAppInit } from './app-init';
import update_all_shop_stats_api_call from './dashboard/update_all_shop_stats_api_call';

export default async function onboarding_state_updates(
  shopId: number,
  stats: any
) {
  // if user skipped the onboarding without shop registration
  if (!shopId) {
    store.dispatch(setIsAiOnboardingCompleted(false));
    store.dispatch(setAiOnboardingInprogress(true));
    return;
  }

  if (shopId && stats && stats?.additional_stats) {
    const additional_stats = stats?.additional_stats;

    // setting ai tracker configuration status
    store.dispatch(
      setIsTrackerClassesConfigured(
        additional_stats?.is_tracker_classes_configured ?? false
      )
    );

    // setting current onboarding step
    if (
      !additional_stats?.dashboard_onboarding_current_step ||
      additional_stats?.dashboard_onboarding_current_step === 0
    ) {
      store.dispatch(setAiOnboardingNextStep(1));
    } else {
      store.dispatch(
        setAiOnboardingNextStep(
          additional_stats?.dashboard_onboarding_current_step
        )
      );
    }

    // when tracker classes are configured and onboarding is not complete (old users)
    if (
      additional_stats?.is_tracker_classes_configured &&
      !additional_stats?.is_dashboard_onboarding_complete
    ) {
      const sendOnboardingStatus = await update_all_shop_stats_api_call(
        3,
        true
      );

      if (sendOnboardingStatus) {
        fetchAppInit(); // Initialize the app
      }
    }

    // When all onboarding steps are completed
    if (additional_stats?.is_dashboard_onboarding_complete) {
      store.dispatch(
        setAiOnboardingStepCompleted({ stepNumber: 1, isCompleted: true })
      );
      store.dispatch(
        setAiOnboardingStepCompleted({ stepNumber: 2, isCompleted: true })
      );
      store.dispatch(
        setAiOnboardingStepCompleted({ stepNumber: 3, isCompleted: true })
      );
      store.dispatch(setIsAiOnboardingCompleted(true));
      store.dispatch(setAiOnboardingInprogress(false));
      store.dispatch(setIsAiOnboardingIntegrationConnected(true));
    }

    // When current onboarding step is 2
    if (
      !additional_stats?.is_dashboard_onboarding_complete &&
      additional_stats?.dashboard_onboarding_current_step === 2
    ) {
      store.dispatch(
        setAiOnboardingStepCompleted({ stepNumber: 1, isCompleted: true })
      );
      store.dispatch(setIsAiOnboardingCompleted(false));
      store.dispatch(setAiOnboardingInprogress(true));
    }

    // When current onboarding step is 3
    if (
      !additional_stats?.is_dashboard_onboarding_complete &&
      additional_stats?.dashboard_onboarding_current_step === 3
    ) {
      store.dispatch(
        setAiOnboardingStepCompleted({ stepNumber: 1, isCompleted: true })
      );
      store.dispatch(
        setAiOnboardingStepCompleted({ stepNumber: 2, isCompleted: true })
      );
      store.dispatch(setIsAiOnboardingCompleted(false));
      store.dispatch(setAiOnboardingInprogress(true));
      store.dispatch(setIsAiOnboardingIntegrationConnected(true));
    }
  }
}
