import { motion } from 'framer-motion';
import '../../../styles/pages/quickOnboarding/quickOnboarding.scss';
import { pageSlideOutForward } from '../../../framer-animations/page-slide-in-out';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import adUpLogoDark from '../../../assets/adup_merchant_logo_dark.png';
import adUpLogoLight from '../../../assets/adup_merchant_logo_light.png';
import QuickOnboardingMain from './QuickOnboardingMain';

const QuickOnboarding = () => {
  const merchantTheme = useSelector(
    (state: RootState) => state.user.merchant.config
  );
  const staticURL = useSelector((state: RootState) => state.app.staticURL);
  const isDarkMode = useSelector((state: RootState) => state.UI.theme.dark);

  return (
    <motion.div {...pageSlideOutForward} className='quickOnboarding'>
      {/* <div className='quickOnboarding__left'>
        <div className='quickOnboarding__left--content'>
          <div className='quickOnboarding__left--image'>
            {merchantTheme?.merchant_logo_light &&
            merchantTheme?.merchant_logo_light !== '' ? (
              <img
                src={`${staticURL}/medium/${
                  isDarkMode
                    ? merchantTheme?.merchant_logo_light
                    : merchantTheme?.merchant_logo_dark
                }`}
                alt={'logo'}
              />
            ) : (
              <img
                src={isDarkMode ? adUpLogoLight : adUpLogoDark}
                alt={'logo'}
              />
            )}
          </div>
        </div>
      </div> */}

      <div className='quickOnboarding__right'>
        <div className='quickOnboarding__content'>
          <QuickOnboardingMain />
        </div>
      </div>
    </motion.div>
  );
};

export default QuickOnboarding;
