import { asyncHandler } from '../async-handler';
import store from '../../../store/store';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import { STATISTICS_BY_SHOP_API } from '../../constants/central-api';

export default async function update_all_shop_stats_api_call(
  onboarding_current_step: number,
  is_onboarding_complete: boolean
) {
  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  const shopSlug = store.getState().user?.company?.shop_slug;
  const ai_onboarding = store.getState().user?.ai_onboarding;
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  if (!shopSlug) {
    return false;
  }

  const requestInfo: RequestInfo = {
    url: `${STATISTICS_BY_SHOP_API(shopSlug)}`,
    method: 'PUT',
    body: JSON.stringify({
      additional_stats: {
        dashboard_onboarding_steps_count: ai_onboarding?.steps?.length || 3,
        dashboard_onboarding_current_step: onboarding_current_step,
        is_dashboard_onboarding_complete: is_onboarding_complete,
      },
    }),
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: accessToken.type + ' ' + sellerToken,
    },
  };

  const actionType = 'UPDATE_ALL_SHOP_STATISTICS';
  const customMsg = 'Shop statistics updated successfully';

  const res: any = await asyncHandler(
    requestInfo,
    actionType,
    customMsg,
    false
  );

  const response = res?.data;
  if (response.success) {
    return true;
  } else {
    return false;
  }
}
