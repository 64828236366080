import { AI_CHECKOUT_TRACKING_LINK } from './central-api';

export const regularScriptCode = (apiKey: string) => {
  return `<!-- AI Checkout Tracking script -->
  <script defer>
    function loadAITrackingScript() {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.defer = true;
      script.src = "${AI_CHECKOUT_TRACKING_LINK(
        apiKey
      )}&t=" + new Date().getTime();
      script.onload = function () {
       initializeAnalytics();
     };
     document.head.appendChild(script);
   }
   document.addEventListener("DOMContentLoaded",function(){const q=new Date(),t=60*q.getHours()+q.getMinutes(),n=JSON.parse(localStorage.getItem("styleRecommendationsByAdUp"))||{};n&&"object"==typeof n&&Object.keys(n).forEach((x=>{const o=x.split("&t=")[0],c=x.split("&t=")[1];if("N"===c && t >= 0 && t < 360 || "M"===c && t >= 360 && t < 720 || "A"===c && t >= 720 && t < 1080 || "E"===c && t >= 1080 && t < 1440)try{document.querySelectorAll(o).forEach((t=>{if(t){const o=n[x];o&&"object"==typeof o&&Object.keys(o).forEach((x=>{t.style[x]=o[x]}))}}))}catch(x){console.log(x)}})),loadAITrackingScript()});
 </script>`;
};

export const shopifyPixelCode = (apiKey: string) => {
  return `(function () {
  function loadAITrackingScript() {
    return new Promise((resolve, reject) => {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src = "${AI_CHECKOUT_TRACKING_LINK(
        apiKey
      )}&t=" + new Date().getTime();
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    });
  }
  function trackShopifyEvent(event) {
    setTimeout(() => {
      window.postMessage(
        {
          type: "shopify_web_pixel_event",
          payload: event,
        },
        "*"
      );
    }, 5000);
  }
  function initializeTracking() {
    const d=new Date(),m=60*d.getHours()+d.getMinutes(),r=JSON.parse(localStorage.getItem("styleRecommendationsByAdUp"))||{};r&&"object"==typeof r&&Object.keys(r).forEach(k=>{const[s,t]=k.split("&t="),v="N"===t&&m>=0&&m<360||"M"===t&&m>=360&&m<720||"A"===t&&m>=720&&m<1080||"E"===t&&m>=1080&&m<1440;if(v)try{document.querySelectorAll(s).forEach(x=>{if(x){const o=r[k];o&&"object"==typeof o&&Object.keys(o).forEach(p=>{x.style[p]=o[p]})}})}catch(x){console.error(x)}});
    loadAITrackingScript()
      .then(() => {
        if (window.initializeAnalytics) {
          window.initializeAnalytics();
        }
      })
      .catch((error) => {
        console.error("Error loading script:", error);
      })
      .finally(() => {
        const eventsToTrack = [
          "cart_viewed",
          "checkout_address_info_submitted",
          "checkout_completed",
          "checkout_contact_info_submitted",
          "checkout_shipping_info_submitted",
          "checkout_started",
          "collection_viewed",
          "payment_info_submitted",
          "product_added_to_cart",
          "product_removed_from_cart",
          "product_viewed",
          "search_submitted",
        ];
        eventsToTrack.forEach((eventName) => {
          analytics.subscribe(eventName, (event) => {
            trackShopifyEvent(event);
          });
        });
      });
  }
  analytics.subscribe("page_viewed", () => {
    initializeTracking();
  });
})();
`;
};
