import { useEffect, useRef, useState } from 'react';
import '../../styles/pages/login.scss';
import { useNavigate } from 'react-router-dom';
import store from '../../store/store';
import {
  setAccessToken,
  setIsLoggedInViaWebAuthn,
  setIsWebAuthnAvailableInAccount,
} from '../../store/reducers/authSlice';
import { useTranslation } from 'react-i18next';
import ToggleSwitch from '../../ui/buttons/ToggleSwitch';
import { TWO_FACTOR_AUTHENTICATION_LOGIN_ENDPOINT } from '../../lib/constants/urls';
import { RequestInfo } from '../../interfaces/RequestInfo';
import { asyncHandler } from '../../lib/api/async-handler';
import { fetchAppInit } from '../../lib/api/app-init';
import MainSquareButton from '../../ui/buttons/MainSquareButton';
import { setIsInitSuccess, setMessage } from '../../store/reducers/appSlice';
import {
  applicationJSONBodyBuilder,
  applicationWWWXRLEncodedBodyBuilder,
} from '../../lib/utils/body-builder';
import moment from 'moment';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import SlideFromBottom from '../../ui/sliders/SlideFromBottom';
import { OTPCodeInput } from '../../ui/inputs/OTPCodeInput';
import RenderLoginPageLogo from './RenderLoginPageLogo';
import {
  APP_ENV,
  LOGIN_API,
  USER_INFO_API,
  WEBAUTHN_LOGIN_API,
} from '../../lib/constants/central-api';
import { submitLogToBugsnag } from '../../lib/api/log';
import { HOME_ROUTE } from '../../lib/constants/route';
import FooterLinks from '../../components/footer/FooterLinks';
import { debounce } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import isNotEmptyString from '../../lib/helper-functions/isNotEmptyString';
import useTitle from '../../customHooks/useTitle';
import { motion } from 'framer-motion';
import { pageSlideOutForward } from '../../framer-animations/page-slide-in-out';
import AINormalInput from '../../ui/inputs/AINormalInput';
import adUpLogoDark from '../../assets/adup_merchant_logo_dark.png';
import adUpLogoLight from '../../assets/adup_merchant_logo_light.png';
import googleButtonImg from '../../assets/googleIcon.png';
import passkeyButtonImg from '../../assets/passkey.png';
import { useGoogleLogin } from '@react-oauth/google';

declare const window: any;

const LoginPage = () => {
  const { t } = useTranslation();
  useTitle(t('SignIn'));
  const emailInputRef = useRef(null);
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [rememberFlag, setRememberFlag] = useState<boolean>(false);
  const [isSliderOpen, setIsSliderOpen] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isWebAuthnButtonVisible, setIsWebAuthnButtonVisible] =
    useState<boolean>(false);
  const authToken = store.getState().auth.authorization.accessToken;
  const is_ai_onboarding_inprogress = useSelector(
    (state: RootState) => state.user?.ai_onboarding?.is_ai_onboarding_inprogress
  );
  const is_ai_onboarding_complete = useSelector(
    (state: RootState) => state.user?.ai_onboarding?.is_ai_onboarding_completed
  );
  const is_tracker_classes_configured = useSelector(
    (state: RootState) => state.user?.stats?.is_tracker_classes_configured
  );

  const [secretKey2fa, setSecretKey2fa] = useState('');
  const [userId2fa, setUseId2fa] = useState('');

  const navigate = useNavigate();

  const merchantTheme = useSelector(
    (state: RootState) => state.user.merchant.config
  );
  const merchantName = useSelector(
    (state: RootState) => state.user?.merchant?.config?.merchant_name
  );
  const staticURL = useSelector((state: RootState) => state.app.staticURL);
  const isDarkMode = useSelector((state: RootState) => state.UI.theme.dark);

  useEffect(() => {
    store.dispatch(
      setMessage({
        message: error,
        messageType: 'error',
      })
    );
  }, [error]);

  useEffect(() => {
    const input: any = emailInputRef.current;

    const checkAutofill = () => {
      if (input && isNotEmptyString(input.value)) {
        getUserDetails(input.value);
      }
    };

    const observer = new IntersectionObserver(checkAutofill);
    observer.observe(input);

    return () => {
      if (observer && input) {
        observer.unobserve(input);
      }
    };
  }, []);

  async function triggerWebAuthn(email: string) {
    setIsDisabled(true);
    try {
      const loginUUID = uuidv4();

      const assertOptionsConfig = {
        path: `${WEBAUTHN_LOGIN_API}/options`,
        body: {
          key: loginUUID,
          email: email,
        },
      };

      const assertConfig = {
        path: LOGIN_API,
        body: {
          webauthn: true,
          key: loginUUID,
        },
      };

      try {
        console.log('WebAuthn : Authenticating...');

        const webpass = window.Webpass.create({
          rpId: APP_ENV === 'LIVE' ? 'adup.io' : 'kodeia.com',
        });

        const { user: response, success }: any = await webpass.assert(
          assertOptionsConfig,
          assertConfig
        );

        if (success) {
          normalLoginProcess(response, true);
          console.log('WebAuthn : Login successful!');
        } else {
          console.log('WebAuthn : Login failed.', response);
          setIsDisabled(false);
        }
      } catch (error) {
        console.log('WebAuthn : Login error.', error);
        submitLogToBugsnag(
          'error',
          `Error in webauthn Login endpoint: ${error}`
        );
        setIsDisabled(false);
      }
    } catch (error) {
      console.log('WebAuthn : Login error.', error);
      submitLogToBugsnag('error', `Error in webauthn Login endpoint: ${error}`);
      setIsDisabled(false);
    }
    setIsDisabled(false);
  }

  const normalLoginProcess = (response: any, isWebAuthnLogin?: boolean) => {
    store.dispatch(setIsInitSuccess(false));
    const token_type = 'Bearer';
    const { access_token, user } = response?.data;

    if (isNotEmptyString(access_token)) {
      store.dispatch(
        setAccessToken({
          sellerToken: access_token,
          type: token_type,
          expiresOn:
            rememberFlag === true
              ? moment().add(21, 'days')
              : moment().add(1, 'days'),
        })
      );

      store.dispatch(setIsWebAuthnAvailableInAccount(user?.webauthn ?? false));
      store.dispatch(setIsLoggedInViaWebAuthn(isWebAuthnLogin ?? false));

      if (
        is_ai_onboarding_inprogress ||
        !is_ai_onboarding_complete ||
        !is_tracker_classes_configured
      ) {
        navigate('/onboarding');
      } else {
        navigate(HOME_ROUTE);
      }

      //make init call to get all info about logged-In user
      fetchAppInit();
    } else {
      console.log('Access Token is empty');
      submitLogToBugsnag('error', `Access Token is empty`);
      setIsDisabled(false);
      setError(t('UnauthorizedAccess'));
    }
  };

  /**
   * @param { string } email The corresponding email of the user
   */
  async function getUserDetails(email: string) {
    setEmailAddress(email);
    setIsWebAuthnButtonVisible(false);

    const emailPattern = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;

    if (emailPattern.test(email) && window.Webpass.isSupported()) {
      const requestInfo: RequestInfo = {
        url: `${USER_INFO_API}`,
        method: 'POST',
        body: JSON.stringify({
          email: email,
        }),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/json',
        },
      };

      const actionType = 'GET_USER_DETAILS';
      const customMsg = 'User Details fetched Successfully.';

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res?.data;

      if (
        res &&
        res?.actionType === actionType &&
        response &&
        response?.success === true
      ) {
        if (response?.data && response?.data?.webauthn) {
          setIsWebAuthnButtonVisible(true);
        }
        return response?.data;
      } else {
        return false;
      }
    }
    return false;
  }

  const emailChangeDebounce = debounce((email) => getUserDetails(email), 600);

  async function handleClick() {
    try {
      const requestInfo: RequestInfo = {
        url: LOGIN_API,
        method: 'POST',
        body: applicationJSONBodyBuilder({
          email: emailAddress,
          password,
          remember_me: rememberFlag ?? false,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };

      const actionType = 'LOGIN_VERIFICATION';
      const customMsg = t('LoginSuccess-msg');

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false,
        true
      );

      const response = res.data;

      if (res.actionType === actionType && response.success === true) {
        if (response?.result?.secret_key) {
          //TODO 2FA Auth - Central API not supported yet
          setSecretKey2fa(response?.result?.secret_key);
          setUseId2fa(response?.result?.user_id);
          setIsSliderOpen(true);
        } else {
          normalLoginProcess(response);
        }
      } else {
        setError(response.message);
        setIsDisabled(false);
        /*  onFormSubmitedFailed(response.message);*/

        // console.log(response);
        /* onFormSubmitedFailed(generateErrorMessage(response)); */
      }
    } catch (exception) {
      setIsDisabled(false);
      console.log('Error during authentication request');
      submitLogToBugsnag('error', `${exception}`);
    }
  }

  /* =============2FA Auth stuff start ================ */
  async function OTPSubmitHandler(otpCode: string) {
    try {
      const sendingData = {
        otp_code: otpCode,
        Tfa_secret: secretKey2fa,
        user_id: userId2fa,
      };

      const requestInfo: RequestInfo = {
        url: TWO_FACTOR_AUTHENTICATION_LOGIN_ENDPOINT,
        method: 'POST',
        body: applicationWWWXRLEncodedBodyBuilder(sendingData),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
        },
      };

      const actionType = 'SENDING-2FA-OTP';
      const customMsg = t('2fa-VerifiedSuccess-msg');

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        normalLoginProcess(response);
        setIsSliderOpen(false);
      }
    } catch (exception) {
      console.log('Error during authentication request');
      submitLogToBugsnag('error', `${exception}`);
    }
  }
  /* =============2FA Auth stuff end ================ */

  /* ============= Google OAuth Sign In start ================ */
  const googleSignIn = async (googleAccessToken: any) => {
    try {
      const sendingData = {
        oauth: 'google',
        role: 'seller',
        token: googleAccessToken,
      };

      const requestInfo: RequestInfo = {
        url: LOGIN_API,
        method: 'POST',
        body: applicationWWWXRLEncodedBodyBuilder(sendingData),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
        },
      };

      const actionType = 'LOGIN_VERIFICATION';
      const customMsg = t('LoginSuccess-msg');

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false,
        true
      );

      const response = res.data;

      if (res.actionType === actionType && response.success === true) {
        normalLoginProcess(response);
      } else {
        setError(response.message);
      }
    } catch (exception) {
      console.log('Error during authentication request');
      submitLogToBugsnag('error', `${exception}`);
    }
  };

  const googleAuthentication = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      googleSignIn(tokenResponse?.access_token);
    },
  });
  /* ============= Google OAuth Sign In end ================ */

  const onFormSubmit = (e: any) => {
    e.preventDefault();

    if (isNotEmptyString(emailAddress) && isNotEmptyString(password)) {
      setIsDisabled(true);
      setTimeout(() => {
        handleClick();
      }, 700);
    }
  };

  const loginView = () => {
    return (
      <motion.div {...pageSlideOutForward} className='login'>
        <div className='login__left'>
          <div className='login__left--content'>
            <div className='login__left--image'>
              {merchantTheme?.merchant_logo_light &&
              merchantTheme?.merchant_logo_light !== '' ? (
                <img
                  src={`${staticURL}/medium/${
                    isDarkMode
                      ? merchantTheme?.merchant_logo_light
                      : merchantTheme?.merchant_logo_dark
                  }`}
                  alt={'logo'}
                />
              ) : (
                <img
                  src={isDarkMode ? adUpLogoLight : adUpLogoDark}
                  alt={'logo'}
                />
              )}
            </div>
          </div>
        </div>

        <form onSubmit={onFormSubmit} className='login__right'>
          <div className='login__logo'>
            <RenderLoginPageLogo
              staticURL={staticURL}
              merchantTheme={merchantTheme}
              isDarkMode={isDarkMode}
            />
          </div>
          <div className='login__content'>
            <div className='login__right--top'>
              <h1>{t('SignIn')}</h1>
              <p>{t('SignInSubText')}</p>
            </div>
            <div className='login__right--center'>
              <AINormalInput
                id='sign-up-form-email'
                ref={emailInputRef}
                value={emailAddress}
                type='email'
                changeListeners={[
                  (e) => {
                    setIsWebAuthnButtonVisible(false);
                    emailChangeDebounce(e.target.value);
                  },
                ]}
                placeholder={t('EnterYourEmailAddress')}
                innerPlaceholder={'you@example.com'}
                required={true}
                pattern='^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
                title={t('Email-validation')}
                autoFocus={true}
              />
              <AINormalInput
                id='sign-up-form-password'
                value={password}
                changeListeners={[(e) => setPassword(e.target.value)]}
                placeholder={t('Password')}
                type='password'
                innerPlaceholder={t('EnterYourPassword')}
                required={true}
              />

              <div className='login__right--center-links'>
                <ToggleSwitch
                  title={t('RememberMe')}
                  onClick={(e: any) => {
                    setRememberFlag(e);
                  }}
                  defaultChecked={rememberFlag}
                />

                <span
                  className='login__right--center-links-forgotPassword'
                  onClick={() => navigate('/forgot-password')}
                >
                  {t('ForgotPassword')}
                </span>
              </div>
            </div>
            <div className='login__right--bottom'>
              <div className='login__right--bottom-button'>
                <MainSquareButton
                  value={t('LoginNow')}
                  disabled={isDisabled}
                  type='submit'
                  classes='mainSquareButton-extra-1'
                />
              </div>

              <div className='login__right--bottom-privacy'>
                <br />
                ⎯⎯⎯⎯⎯⎯⎯⎯⎯ {t('Or')} ⎯⎯⎯⎯⎯⎯⎯⎯⎯
              </div>

              <div className='login__right--other'>
                <div className='login__right--bottom-button'>
                  <MainSquareButton
                    value={t('SignInWithGoogle')}
                    disabled={isDisabled}
                    image={googleButtonImg}
                    type='button'
                    onClick={() => {
                      googleAuthentication();
                    }}
                  />
                </div>
              </div>
              {isWebAuthnButtonVisible && (
                <div className='login__right--other'>
                  <div className='login__right--bottom-button'>
                    <MainSquareButton
                      value={t('SignInWithPasskey')}
                      disabled={isDisabled}
                      image={passkeyButtonImg}
                      type='button'
                      onClick={() => {
                        triggerWebAuthn(emailAddress);
                      }}
                    />
                  </div>
                </div>
              )}

              <div className='login__right--bottom-links'>
                <h2>
                  {t('Or')}{' '}
                  <span onClick={() => navigate('/register')}>
                    {t('Create')}
                  </span>{' '}
                  {t('An-Account')}
                </h2>
              </div>

              <div className='login__right--bottom-privacy'>
                <FooterLinks />
              </div>
            </div>
          </div>
        </form>

        {isSliderOpen && (
          <SlideFromBottom
            hideSegment={() => {
              setIsSliderOpen(false);
            }}
          >
            <div className='login__otp'>
              <h1>{t('EnterOTP')}</h1>
              <OTPCodeInput
                numberOfFields={6}
                onComplete={(code: string) => {
                  OTPSubmitHandler(code);
                }}
              />
            </div>
          </SlideFromBottom>
        )}
      </motion.div>
    );
  };

  if (!authToken.sellerToken) {
    return loginView();
  } else {
    window.location.assign('/');
    return null;
  }
};

export default LoginPage;
