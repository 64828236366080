import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  authorization: {
    accessToken: {
      sellerToken: '',
      type: 'Bearer',
      expiresOn: null as any,
      whiteLabelSellerTokens: {} as any,
      currentWhiteLabelSellerToken: '',
    },
    webAuthn: {
      isWebAuthnAvailableInAccount: false as boolean,
      isLoggedInViaWebAuthn: false as boolean,
      isWebAuthnRegistrationSkipped: false as boolean,
    },
    whiteLabelRedirectPath: '',
    subscriptionLock: 'pending',
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccessToken: (
      state,
      action: PayloadAction<{
        sellerToken: any;
        type: string;
        expiresOn: any;
        whiteLabelSellerTokens?: any;
        currentWhiteLabelSellerToken?: any;
      }>
    ) => {
      state.authorization.accessToken.sellerToken =
        action.payload.sellerToken ?? undefined;
      state.authorization.accessToken.type = action.payload.type;
      state.authorization.accessToken.expiresOn = action.payload.expiresOn;
      state.authorization.accessToken.whiteLabelSellerTokens =
        action.payload?.whiteLabelSellerTokens;
      state.authorization.accessToken.currentWhiteLabelSellerToken =
        action.payload?.currentWhiteLabelSellerToken;
    },

    setWhiteLabelRedirectPath: (state, action: PayloadAction<string>) => {
      state.authorization.whiteLabelRedirectPath = action.payload;
    },

    setIsWebAuthnAvailableInAccount: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.authorization.webAuthn.isWebAuthnAvailableInAccount =
        action.payload;
    },

    setIsLoggedInViaWebAuthn: (state, action: PayloadAction<boolean>) => {
      state.authorization.webAuthn.isLoggedInViaWebAuthn = action.payload;
    },

    setIsWebAuthnRegistrationSkipped: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.authorization.webAuthn.isWebAuthnRegistrationSkipped =
        action.payload;
    },

    clearAuthSlice: (state) => {
      state.authorization = {
        accessToken: {
          sellerToken: '',
          type: 'Bearer',
          expiresOn: null,
          whiteLabelSellerTokens: {},
          currentWhiteLabelSellerToken: '',
        },
        webAuthn: {
          isWebAuthnAvailableInAccount: false,
          isLoggedInViaWebAuthn: false,
          isWebAuthnRegistrationSkipped: false,
        },
        subscriptionLock: 'pending',
        whiteLabelRedirectPath: '',
      };
    },

    setSubscriptionLock: (state, action) => {
      state.authorization.subscriptionLock = action.payload;
    },
  },
});

export const authReducer = authSlice.reducer;

export const {
  setAccessToken,
  clearAuthSlice,
  setSubscriptionLock,
  setWhiteLabelRedirectPath,
  setIsWebAuthnAvailableInAccount,
  setIsLoggedInViaWebAuthn,
  setIsWebAuthnRegistrationSkipped,
} = authSlice.actions;
