import { useTranslation } from 'react-i18next';
import '../../../../styles/components/charts/pieChartComponent.scss';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { useEffect, useState } from 'react';
import LoadingSpinner from '../../../../ui/loadingSpinner/LoadingSpinner';
import ChartLegend from '../../ChartLegend';
import NoData from '../../../../ui/noData/NoData';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import get_graph_data_api_call from '../../../../lib/api/stats/get_graph_data_api_call';
import isNotEmptyString from '../../../../lib/helper-functions/isNotEmptyString';

type Props = {
  timeFilter: string;
  customPeriodFilterDateRange: {
    start_date: string | undefined;
    end_date: string | undefined;
  };
  eventFilter: string;
};

const OverallConversionRatePieChart = ({
  timeFilter = 'all',
  customPeriodFilterDateRange,
  eventFilter = 'purchase',
}: Props) => {
  const { t } = useTranslation();
  const stats = useSelector((state: RootState) => state.user?.stats);
  const [conversionRate, setConversionRate] = useState(0);
  const [params, setParams] = useState<string>(
    `?graph_type=conversion_rate&time_range=${timeFilter}&event_type=${eventFilter}`
  );
  const [chartData, setChartData] = useState<any[]>([]);
  const [loading, setLoading] = useState<
    'pending' | 'success' | 'error' | 'null'
  >('pending');

  /* ---------API CALL----------- */
  const getGraphData = async () => {
    setLoading('pending');
    try {
      // Call to get conversion rate stats by location
      const res = await get_graph_data_api_call(
        stats.ai_tracker_api_key,
        stats.ai_tracker_access_token,
        params
      );

      if (res?.conversion_rate && res?.conversion_rate > 0) {
        getConversionRateData(res);
      } else {
        setLoading('null');
        return;
      }
    } catch (error) {
      console.error('Error fetching conversion rate data:', error);
    }
  };

  const getConversionRateData = async (res: any) => {
    const totalCarts = res?.total_carts ? res?.total_carts : 0;
    // const totalPurchases = res?.data?.total_purchases ? res?.data.total_purchases : 0;
    const conversionRate = res?.conversion_rate
      ? parseFloat(res?.conversion_rate?.toFixed(2))
      : 0;
    const remainingRate = parseFloat((100 - conversionRate)?.toFixed(2));

    setChartData([
      {
        label: t('Purchases'),
        value: conversionRate,
        color: '#82ca9d',
      },
      {
        label: t('RemainingCarts'),
        value: remainingRate,
        color: '#8884d8',
      },
    ]);

    setConversionRate(conversionRate);
    setLoading('success');
    return;
  };

  useEffect(() => {
    let dateRangeURLParam = '';
    if (timeFilter === 'custom') {
      const { start_date, end_date } = customPeriodFilterDateRange || {};
      if (start_date === undefined || end_date === undefined) {
        return;
      }
      dateRangeURLParam = `&start_date=${start_date}&end_date=${end_date}`;
    }

    let eventURLParam = '';
    if (isNotEmptyString(eventFilter)) {
      eventURLParam = `&event_type=${eventFilter}`;
    }

    setParams(
      `?graph_type=conversion_rate&time_range=${timeFilter}${dateRangeURLParam}${eventURLParam}`
    );
  }, [timeFilter, customPeriodFilterDateRange, eventFilter]);

  useEffect(() => {
    if (
      stats.ai_tracker_api_key !== '' &&
      stats.ai_tracker_access_token !== ''
    ) {
      getGraphData();
    }
  }, [stats, params]);

  const valueFormatter = (item: { value: number }) => `${item.value}%`;

  const data = {
    data: chartData,
    valueFormatter,
  };

  return (
    <div className='pieChartComponent'>
      <h2 className='pieChartComponent__title'>{t('OverallConversionRate')}</h2>
      <p className='pieChartComponent__title--sub'>
        {t('TheOverallConversionRate')}
      </p>
      <div className='pieChartComponent__chart'>
        {loading === 'pending' && <LoadingSpinner />}

        {loading === 'null' && (
          <NoData
            title={t('No data available')}
            subTitle={t('NeedMoreInteractionsToGenerateStats')}
          />
        )}
        {loading === 'success' && (
          <div className='pieChartComponent__chart--container'>
            <div
              style={{ height: '23rem' }}
              className='pieChartComponent__chart--container-chart'
            >
              <PieChart
                margin={{ top: 5, bottom: 5, left: 5, right: 5 }}
                series={[
                  {
                    arcLabel: (item) => `${item.value}%`,
                    arcLabelMinAngle: 35,
                    arcLabelRadius: '60%',
                    ...data,
                  },
                ]}
                sx={{
                  [`& .${pieArcLabelClasses.root}`]: {
                    fontWeight: 'bold',
                  },
                }}
                slotProps={{
                  legend: {
                    hidden: true, // Set to true to hide the legend
                  },
                }}
              />
            </div>
            <div className='pieChartComponent__chart--container-legend'>
              {chartData?.map((item, index) => (
                <ChartLegend
                  key={index}
                  color={item?.color}
                  label={item.label}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OverallConversionRatePieChart;
