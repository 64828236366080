import { clearAuthSlice } from '../../store/reducers/authSlice';
import { clearUserSlice } from '../../store/reducers/userSlice';
import store from '../../store/store';

declare const window: any;

function localStorageHardReset() {
  const webAuthnRegSkipCount =
    JSON.parse(window.localStorage.getItem('webAuthnRegSkipCount') || '{}') ||
    {};
  store.dispatch(clearUserSlice());
  store.dispatch(clearAuthSlice());
  window.localStorage.clear();
  window.localStorage.setItem(
    'webAuthnRegSkipCount',
    JSON.stringify(webAuthnRegSkipCount)
  );
}

export default localStorageHardReset;
