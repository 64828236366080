import React from 'react';
import '../../../styles/pages/quickOnboarding/quickOnboardingProgress.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { setAiOnboardingNextStep } from '../../../store/reducers/userSlice';
import { useTranslation } from 'react-i18next';

const QuickOnboardingProgress = () => {
  const { t } = useTranslation();
  const currentStep = useSelector(
    (state: RootState) => state.user?.ai_onboarding?.currentStep || 1
  );
  const steps = useSelector(
    (state: RootState) => state.user?.ai_onboarding?.steps || []
  );

  const numberOfTransactions = useSelector(
    (state: RootState) => state.user?.company?.transaction_per_month || ''
  );

  const dispatch = useDispatch();

  const onStepClick = (stepNumber: number) => {
    if (stepNumber === 1) {
      dispatch(setAiOnboardingNextStep(stepNumber));
      return;
    }

    // Check if the previous step is completed before moving to the next step
    if (steps[stepNumber - 2]?.isCompleted) {
      // Check if the user is on step 2
      if (stepNumber === 2) {
        if (
          numberOfTransactions === '5000-10000' ||
          numberOfTransactions === '10000+'
        ) {
          // if user is on step 2 and number of transactions is 5000-10000 or 10000+ then disable the step 2 click
          return;
        } else {
          // if user is on step 2 and number of transactions is less than 5000 then enable the step 2 click
          dispatch(setAiOnboardingNextStep(stepNumber));
        }
      }
      // if user is not on step 2 and previous step is completed then enable the step click
      dispatch(setAiOnboardingNextStep(stepNumber));
    }
  };

  return (
    <div className='quickOnboardingProgress'>
      {steps?.map((step, index) => (
        <div
          data-is-highlighted={step?.number <= currentStep ? 'true' : 'false'}
          key={index}
          className='quickOnboardingProgress__step'
          style={{ flex: `${step?.number !== steps?.length ? 1 : 0}` }}
        >
          <div
            title={`Go to Step ${step?.number}`}
            onClick={() => {
              onStepClick(step?.number);
            }}
            className='quickOnboardingProgress__step--number'
          >
            <div className='quickOnboardingProgress__step--number-content'>
              {step?.number}
            </div>
          </div>
          <div
            onClick={() => {
              onStepClick(step?.number);
            }}
            className='quickOnboardingProgress__step--title'
          >
            {t(`${step?.title}`)}
          </div>
          {step?.number !== steps?.length && (
            <hr className='quickOnboardingProgress__step--line'></hr>
          )}
        </div>
      ))}
    </div>
  );
};

export default QuickOnboardingProgress;
