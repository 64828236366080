import { asyncHandler } from '../async-handler';
import store from '../../../store/store';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import { ONBOARDING_INTEGRATION_ZOHO_API } from '../../constants/central-api';

export default async function onboard_integration_zoho_api_call(type: string) {
  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  const sellerID = store.getState().user?.profile?.seller_id;
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelSellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  if (!sellerID) {
    return false;
  }

  const formData = new FormData();
  formData.append('type', type);
  formData.append('integration_type', 'zoho');

  const requestInfo: RequestInfo = {
    url: `${ONBOARDING_INTEGRATION_ZOHO_API(sellerID)}`,
    method: 'POST',
    body: formData,
    headers: {
      Accept: 'application/json',
      Authorization: accessToken.type + ' ' + sellerToken,
    },
  };

  const actionType = 'ONBOARD_INTEGRATION_ZOHO';
  const customMsg = 'We’ll get in touch as soon as possible';

  const res: any = await asyncHandler(requestInfo, actionType, customMsg, true);

  const response = res?.data;
  if (response.success) {
    return true;
  } else {
    return false;
  }
}
