import '../../styles/components/dropdowns/profileDropdown.scss';
import userPic from '../../imgs/user.jpg';
import CustomSVGs from '../../ui/customSVGs/CustomSVGs';
import {
  logoutSVG,
  sunSVG,
  moonSVG,
  userSettingSVG,
} from '../../lib/allCustomSVGs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MainSquareButton from '../../ui/buttons/MainSquareButton';
import { RootState } from '../../store/store';
import { useTranslation } from 'react-i18next';
import { tokenRevokeAtLogOutUser } from '../../lib/api/logout-user';
import { setAppThemeMode } from '../../store/reducers/uiSlice';
import { APP_ENV } from '../../lib/constants/central-api';
import localStorageHardReset from '../../lib/helper-functions/localStorageHardReset';

const ProfileDropdown = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userProfile = useSelector((state: RootState) => state.user.profile);
  const appTheme = useSelector((state: RootState) => state.UI.theme.dark);
  const accessToken = useSelector(
    (state: RootState) => state.auth.authorization.accessToken
  );

  const logoutHandler = () => {
    tokenRevokeAtLogOutUser();
    if (APP_ENV === 'LIVE') {
      // @ts-ignore:next-line
      window.Intercom && window.Intercom('shutdown'); //shutdown intercom
    }

    localStorageHardReset();

    navigate(
      window?.location?.pathname === '/register' ? '/register' : '/login'
    );
  };

  return (
    <div className='profileDropdown'>
      <div className='profileDropdown__top'>
        <div className='profileDropdown__top--pic'>
          <img src={userPic} alt='user-pic' />
        </div>
        <div className='profileDropdown__top--text'>
          <p>
            {userProfile?.first_name || userProfile?.last_name
              ? `${userProfile.first_name} ${userProfile.last_name}`
              : `${userProfile.email}`}
          </p>
          <span>{t('Seller')}</span>
        </div>
      </div>
      <div className='profileDropdown__center'>
        <div
          onClick={() => {
            navigate('/settings/profile');
          }}
          className='profileDropdown__center--item'
        >
          <div
            data-profile-settings-mode={appTheme?.toString()}
            className='profileDropdown__center--item-left profileDropdown__center--item-mode'
          >
            {<CustomSVGs svg={userSettingSVG} />}
          </div>
          <div className='profileDropdown__center--item-right'>
            {<p>{t('ProfileSettings')}</p>}
          </div>
        </div>
      </div>
      {/* ---------------------------------------------------------- */}
      {/* <hr /> */}
      {/* ---------------------------------------------------------- */}
      <div className='profileDropdown__center'>
        <div
          onClick={() => {
            dispatch(setAppThemeMode());
          }}
          className='profileDropdown__center--item'
        >
          <div
            data-profile-mode={appTheme?.toString()}
            className='profileDropdown__center--item-left profileDropdown__center--item-mode'
          >
            {appTheme ? (
              <CustomSVGs svg={sunSVG} />
            ) : (
              <CustomSVGs svg={moonSVG} />
            )}
          </div>
          <div className='profileDropdown__center--item-right'>
            {!appTheme && <p>{t('SwitchToDarkMode')}</p>}
            {appTheme && <p>{t('SwitchToLightMode')}</p>}
          </div>
        </div>
      </div>
      <div className='profileDropdown__bottom'>
        <MainSquareButton
          value={t('Logout')}
          icon={<CustomSVGs svg={logoutSVG} />}
          disabled={false}
          onClick={(e) => {
            e.preventDefault();
            logoutHandler();
          }}
        />
      </div>
    </div>
  );
};

export default ProfileDropdown;
